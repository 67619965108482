<template>
  <h1>Booking/Contact:</h1>
  <h2>killgosling@gmail.com</h2>
  <!-- <img src="@/assets/img/promo-pic-bw.png" class="promo-pic"> -->
</template>

<script>
export default {
  name: 'ContactView',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.promo-pic {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: -4px;
  width: 800px;
  max-width: 100%
}

</style>
